import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { InputTeaxtArea } from '../CustomElements';

 const ConfirmationTextDialog = ({handleCloseDialog, handleClick, isOpen, title,SubTitle, sumbitTxt, cancelTxt, reason, placeholder}) => {
  return (
    <div>
      <Dialog
        maxWidth='sm'
        fullWidth={true}
        open={isOpen}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle sx={{fontFamily: 'Brandon', textAlign: 'center', paddingBottom: '0'}} id="responsive-dialog-title">
            {title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText sx={{fontFamily: 'Brandon', textAlign: 'center'}}>
            {SubTitle}
          </DialogContentText>
        </DialogContent>
        <InputTeaxtArea
            type="textarea"
            placeholder={placeholder}
            minRows={2}
            size="large"
            sx={{fontFamily: 'Brandon', height:"40px", fontSize: "1rem", border: "1px solid #ced4d9", borderRadius:'3px', padding: "8.5px 14px", margin:"0px 25px"}}
            onChange={(e) => {reason(e.target.value)}}
            />
        <DialogActions sx={{mt: 3, justifyContent: 'center'}}>
          <Button className="cancel-action-btn" sx={{fontFamily: 'Brandon'}} variant='contained'  autoFocus onClick={handleCloseDialog}>
            {cancelTxt}
          </Button>
          <Button className="default-btn" sx={{fontFamily: 'Brandon'}} variant='contained' onClick={handleClick} autoFocus>
            {sumbitTxt}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default ConfirmationTextDialog