import { Route, BrowserRouter as Router, Routes, useNavigate } from 'react-router-dom';
import { UsersRoutes, otherRoutes } from './Modules/Authentication/Constants/routes';
import DecisionComponent from './Components/DecisionComponent';
import './App.css';
import { useEffect } from 'react';
import FirstLoginFlow from './Components/FirstLoginFlow';

function App() {
  const token = localStorage.getItem('token')
  const authStore = window.localStorage.getItem('AuthStore')
  console.log('appjss')

  useEffect(() => {
    const googleTranslateElementInit = () => {
      new window.google.translate.TranslateElement(
        {
          autoDisplay: false,
          includedLanguages: 'en,fr,de'
        },
        'google_translate_element'
      )
    }
    var addScript = document.createElement('script')
    addScript.setAttribute(
      'src',
      '//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit'
    )
    document.body.appendChild(addScript)
    window.googleTranslateElementInit = googleTranslateElementInit
  }, [])
  return (
    <>
      <div id="google_translate_element"></div>
      <Router>
        <Routes>
          {token && authStore && UsersRoutes.map(({ Element, path }) => (
            <Route element={<Element />} path={path} key={path}/>
          ))}
          {otherRoutes.map(({ Element, path }) => (
            <Route element={<Element />} path={path} key={path}/>
          ))}
        <Route element={<DecisionComponent />} path="*" />
        </Routes>
      </Router>
      {/* <FirstLoginFlow/> */}
    </>
  );
}

export default App;
