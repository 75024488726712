import { Box, Menu, MenuItem, Tooltip, Typography } from "@mui/material"
import './style.css'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import IconButton from "@mui/material/IconButton";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { redirectToUrl } from "../../Utils/helper";
import { assests } from "../../Assets/assets";
import MobileMasthead from "./MobileMasthead";
import Avatar from "@mui/material/Avatar";

const Masthead = () => {
  const [anchorElUser, setAnchorElUser] = useState(null);
  const navigate = useNavigate()
  const authStore = window.localStorage.getItem('AuthStore')
  const token = window.localStorage.getItem('token')
  const parseAuthStore = JSON.parse(authStore);
  const redirectToProfile = () => navigate("/dashboard/profile");
  const redirectToDashboard = () => navigate("/dashboard");

  const redirectToDogs = () => navigate("/dashboard/dog");

  const logout = () => {
    localStorage.clear();
    navigate("/login");
  };

  let settings = [
    { key: "My Profile", callback: redirectToProfile },
    { key: "My Dogs", callback: redirectToDogs },
    { key: "Dashboard", callback: redirectToDashboard },
    { key: "Logout", callback: logout },
  ];

  useEffect(() => {
    const serialisedData = JSON.parse(authStore);
    if (
      serialisedData?.profile_status != "completed" &&
      serialisedData?.profile_step != "completed"
    ) {
      settings.splice(0, 2);
    }
  }, []);

    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
      };
    
      const handleCloseUserMenu = () => {
        setAnchorElUser(null);
      };


    return(
        <>
        <Box className="new-desktop-masthead" sx={{padding: '1em 2em 1em 2em', display: 'flex', fontFamily: "Roboto, Sans-serif"}}>
            <Box sx={{width: '17%'}}>
                <img style={{width: '61%', cursor: 'pointer'}} onClick={() => redirectToUrl('https://dogstays.lu')} src={assests.dogstaysLogo} alt='DogStays'/>
            </Box>
            <Box sx={{width: '65.332%', margin: 'auto', mb: 0}}>
                <div style={{display: 'flex', justifyContent: 'center'}}>
                   {authStore && token && <Box>
                        <div className="menu-header">
                            <p onClick={() => navigate('/dashboard')} style={{lineHeight: '24px'}}>Home</p>
                        </div>
                    </Box>}
                    <Box>
                        <div className="menu-header">
                            <p onClick={() => redirectToUrl('www.dogstays.lu/services')}>Services</p>
                            {<ArrowDropDownIcon sx={{color: '#FF9315', marginLeft: '5px'}}/>}
                        </div>
                        <div className="menu-header-dropdown highlight">
                            <ul>
                                <li onClick={() => redirectToUrl('https://www.dogstays.lu/services/#overnight')}>Overnight</li>
                                <li onClick={() => redirectToUrl('https://www.dogstays.lu/services/#daycare')}>Daycare</li>
                            </ul>
                        </div>
                    </Box>
                    <Box>
                        <div className="menu-header">
                            <p onClick={() => redirectToUrl('https://www.dogstays.lu/book')}>Book a Dog Sitter</p>
                            {<ArrowDropDownIcon sx={{color: '#FF9315', marginLeft: '5px'}}/>}
                        </div>
                        <div className="menu-header-dropdown">
                            <ul>
                                <li onClick={() => redirectToUrl('https://www.dogstays.lu/book/process')}>Process</li>
                                <li onClick={() => redirectToUrl('https://www.dogstays.lu/book/pricing')}>Pricing</li>
                            </ul>
                        </div>
                    </Box>
                    {!authStore && !token && <Box>
                        <div className="menu-header">
                            <p onClick={() => redirectToUrl('https://www.dogstays.lu/dogsitters')}>For Dog Sitters</p>
                            {<ArrowDropDownIcon sx={{color: '#FF9315', marginLeft: '5px'}}/>}
                        </div>
                        <div className="menu-header-dropdown">
                            <ul>
                                <li onClick={() => redirectToUrl('https://www.dogstays.lu/dogsitters/#application-process')}>Our Application Process</li>
                                <li onClick={() => redirectToUrl('https://www.dogstays.lu/dogsitters/#testimonials')}>Dog Sitter Testimonials</li>
                            </ul>
                        </div>
                    </Box>}
                    <Box>
                        <div className="menu-header">
                            <p onClick={() => redirectToUrl('https://www.dogstays.lu/faqs')}>FAQ</p>
                            {<ArrowDropDownIcon sx={{color: '#FF9315', marginLeft: '5px'}}/>}
                        </div>
                        <div className="menu-header-dropdown">
                            <ul>
                                <li onClick={() => redirectToUrl('https://www.dogstays.lu/faqs-parents')}>Dog Parent FAQs</li>
                                <li onClick={() => redirectToUrl('https://www.dogstays.lu/faqssitters')}>Dog Sitter FAQs</li>
                            </ul>
                        </div>
                    </Box>
                    <Box>
                        <div className="menu-header">
                            <p>More</p>
                            {<ArrowDropDownIcon sx={{color: '#FF9315', marginLeft: '5px'}}/>}
                        </div>
                        <div className="menu-header-dropdown">
                            <ul>
                                <li onClick={() => redirectToUrl('https://www.dogstays.lu/media-coverage')}>Media Coverage</li>
                                <li onClick={() => redirectToUrl('https://www.dogstays.lu/about-us')}>About Us</li>
                                <li onClick={() => redirectToUrl('https://www.dogstays.lu/contact')}>Contact Us</li>
                            </ul>
                        </div>
                    </Box>
                </div>
            </Box>
            <Box className="user-profile-data">
                <img className="luxembourg-icon" src={assests.Luxembourg}></img>
                {!authStore && !token ? <button className="login-sitter-btn" onClick={() => navigate('/login')}>Login as Dog Parent</button> :
                <Box>
                {authStore && token && (
                  <Tooltip>
                    <IconButton onClick={handleOpenUserMenu} sx={{ p: 0, ml:2, mt:1}}>
                      {authStore ? (
                        <Avatar
                          sx={{ border: "1px solid #80808075" }}
                          src={parseAuthStore.photo}
                        />
                      ) : (
                        <Avatar sx={{ border: "1px solid #80808075" }} />
                      )}
                    </IconButton>
                  </Tooltip>
                )}
                <Menu
                  sx={{ mt: "45px" }}
                  id="menu-appbar"
                  anchorEl={anchorElUser}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={Boolean(anchorElUser)}
                  onClose={handleCloseUserMenu}
                >
                  {settings.map((setting) => (
                    <MenuItem key={setting.key}>
                      <Typography
                        onClick={setting.callback}
                        textAlign="center"
                      >
                        {setting.key}
                      </Typography>
                    </MenuItem>
                  ))}
                </Menu>
              </Box>}
            </Box>
        </Box>
        <MobileMasthead/>
        </>
        
    )
}

export default Masthead
