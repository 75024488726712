import { Box, Button, Grid, Typography } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { createOTP, updateUserInfo, verifyEmailOTP, verifyMobileOTP } from "../../../../Utils/Api";
import { assests } from "../../../../Assets/assets";
import { CustomLabelInput, FormInput, InputField } from "../../../../Components/CustomElements";
import SnackBar from "../../../../Components/Snackbar";
import { getItem, removeItem, setItem } from "../../../../Utils/localStorage";
import PhoneInput from "react-phone-input-2";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import "../style.css"
import DynamicInfoDialog from "../../../../Components/Dialogs/dynamicInfoDialog";
import { isAuthorised } from "../../../../Utils/helper";
import Masthead from "../../../../Components/Masthead";

const UserVerification = () => {
const navigate = useNavigate()
const [emailVerification, setEmailVerification] = useState({email: '', otp: '', status: '', disabled: false})
const [mobileVerification, setMobileVerification] = useState({mobile: '', otp: '', status: '', countryCode: '', disabled: false})
const [verifyEmailStatus, setVerifyEmailStatus] = useState(false)
const [verifyMobileStatus, setVerifyMobileStatus] = useState(false)
const [snackbar, setSnackbar] = useState({ isOpen : false, message: '', type: ''})
const [userForm, setUserForm] = useState({ firstName: '', lastName: '', password: '', cnfPassword: ''})
const [passwordInfoDialog, setPasswordInfoDialog] = useState(false)
const [handlePasswords, setHandlePasswords] = useState({
  showPassword: false,
  passwordType: 'password',
  showCnfPassword: false,
  cnfpasswordType: 'password'
})
const theme = createTheme();

useEffect(() => {
  const origin = window.localStorage.getItem('origin');
  const getGoogleEmail = window.localStorage.getItem('email');
  if(origin == 'social'){
    setEmailVerification({...emailVerification, email:getGoogleEmail, status: 'verified', disabled: true})
  }
}, [])


const vaildPasswordRegx = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#@$!%*?&])[A-Za-z\d#@$!%*?&]{8,}$/;

const handleCreateEmailOtp = async() => {
  if(emailVerification.email == ''){
    setSnackbar({...snackbar, isOpen: true, message: `Please Enter Your Email `, type: 'error'})
  }else{
    await createOTP({
      email: emailVerification.email
    }).then((res) => {
      if(res.data.response_code == 90){
        setVerifyEmailStatus(false)
        setSnackbar({...snackbar, isOpen: true, message: "Email Already Exist", type: 'error'})
      }
      if(res.data.response_code == 80){
        setVerifyEmailStatus(true)
        
      }
    })
  }
  }

const handleCreateMobileOtp = async() => {
  if(mobileVerification.mobile == ''){
    setSnackbar({...snackbar, isOpen: true, message: `Please Enter your Mobile `, type: 'error'})
  }else{
    await createOTP({
      mobile: mobileVerification.mobile,
      country_code: mobileVerification.countryCode
    })
    setVerifyMobileStatus(true)}
}

const handleVerifyEmailOtp = async() => {
    await verifyEmailOTP({
      email:emailVerification.email,
      otp:emailVerification.otp
    }).then((res) => {
        if(res.data.response_code == 90){
          setSnackbar({...snackbar, isOpen: true, message: `${res.data.ui_message}`, type: 'error'})
        }else if(res.data.response_code == 80){
          setItem('token', res.data.data.token);
          setSnackbar({...snackbar, isOpen: true, message: "Email Verified", type: 'success'})
          setVerifyEmailStatus(false)
          setEmailVerification({...emailVerification, disabled:true, status: 'verified'})
        }
    })
}

const handleVerifyMobileOtp = async() => {
    await verifyMobileOTP({
      mobile: mobileVerification.mobile,
      country_code: mobileVerification.countryCode,
      otp: mobileVerification.otp
    }).then((res) => {
      if(res.data.response_code == 90){
        setSnackbar({...snackbar, isOpen: true, message: `${res.data.ui_message}`, type: 'error'})
      }else{
        setSnackbar({...snackbar, isOpen: true, message: "Mobile Verified", type: 'success'})
        setVerifyMobileStatus(false)
        setMobileVerification({...mobileVerification, status: 'verified', disabled: true})
      }
  })
}

const handleEmailData = (e) => {
    setEmailVerification({
      ...emailVerification,
      [e.target.name]: e.target.value,
    });
  };

const handleMobileData = (e) => {
    setMobileVerification({
      ...mobileVerification,
      [e.target.name]: e.target.value,
    });
  };

const handleUpdateUserInfo = () => {
if (vaildPasswordRegx.test(userForm.password)){
  if(userForm.password !== userForm.cnfPassword){
    setSnackbar({...snackbar, isOpen: true, message: "Password doesn't Match", type: 'error'})
  }else if(userForm.firstName == '' || userForm.lastName == '' || userForm.password == ''){
    setSnackbar({...snackbar, isOpen: true, message: "Oops! Looks like you have missed a question", type: 'error'})
  }else if(emailVerification.status !== 'verified' || mobileVerification.status !== 'verified'){
    setSnackbar({...snackbar, isOpen: true, message: "Verify Both Email & Password", type: 'error'})
  }else{
  updateUserInfo({
    first_name: userForm.firstName,
    last_name: userForm.lastName,
    password: userForm.password
  }).then((res) => {
    if(res.data.response_code == 90){
      setSnackbar({...snackbar, isOpen: true, message: `${res.data.ui_message}`, type: 'error'})
    }else if(res.data.response_code == 80){
      const userData = res.data.data
      setItem('AuthStore', JSON.stringify(userData))
      navigate('/user-registration')
    }
  })}
}else{setPasswordInfoDialog(true)}

}

return(
    <>
    <ThemeProvider theme={theme}>
      <Masthead/>
      <Grid container component="main" sx={{ height: "100vh" }}>
        <Grid
          item xs={false} sm={4} md={6} sx={{
            backgroundImage: `url(${assests.newBgImg})`,
            backgroundRepeat: "no-repeat",
            backgroundColor: (t) =>
              t.palette.mode === "light"
                ? t.palette.grey[50]
                : t.palette.grey[900],
            backgroundSize: "cover",
            backgroundPosition: "center",
            height: '100vh'
          }}
        />
        <Grid className="form-verification-container"   item xs={12} sm={8} md={6} elevation={6} sx={{boxShadow: 'none'}}>
        <div className="wrapper" style={{backgroundColor: 'white', padding: '15px 20px', borderRadius:'10px'}}>
          <h1 style={{fontFamily:'Amatic', textAlign:'center', margin:'0'}}>Hello!</h1>
            <p style={{fontSize: '16px', fontFamily:'Brandon', color: '#444140', textAlign: 'center'}}>
              Let's get started. First, please share the below details with us. You also need to set a password for your DogStays account.
               The next time you want to send us a booking request you can use this email address and password to login to your DogStays dashboard and send us a request from there.
            </p>
              <Grid item xs={12}>
                <FormInput fullWidth variant="standard">
                    <CustomLabelInput shrink='true' htmlFor="first_name">
                      First Name <span style={{color: 'red'}}>*</span>
                    </CustomLabelInput>
                    <InputField
                    name="first_name"
                    type="text"
                    id="first_name"
                    size="small"
                    placeholder="Enter Name"
                    onChange={(e) => {setUserForm({...userForm, firstName: e.target.value})}}
                    value={userForm.firstName}
                    />
                </FormInput>
              </Grid>
              <Grid item xs={12}>
                <FormInput fullWidth variant="standard">
                    <CustomLabelInput shrink='true' htmlFor="last_name">
                    Last Name <span style={{color: 'red'}}>*</span>
                    </CustomLabelInput>
                    <InputField
                    name="last_name"
                    type="text"
                    id="last_name"
                    size="small"
                    placeholder="Enter Name"
                    onChange={(e) => {setUserForm({...userForm, lastName: e.target.value})}}
                    value={userForm.lastName}
                    />
                </FormInput>
              </Grid>

          <Grid container sx={{display:'flex', alignItems:'end' }}>
            <Grid item xs={9}>
                <FormInput fullWidth variant="standard">
                    <CustomLabelInput shrink='true' htmlFor="email">
                      Email Address <span style={{color: 'red'}}>*</span>
                    </CustomLabelInput>
                    <InputField
                    name="email"
                    type="email"
                    id="email"
                    size="small"
                    placeholder="Enter Email"
                    onChange={handleEmailData}
                    value={emailVerification.email}
                    disabled={emailVerification.disabled}
                    />
                </FormInput>
                </Grid>
                <Grid item xs={3} sx={{ textAlign: "center" }}>
               {emailVerification.status == 'verified' ? 
               <Typography className="verified-badge" component='h5' >Verified</Typography>
               :
               <Button className="default-btn" variant="contained" sx={{marginBottom:'2px'}}
                    onClick={handleCreateEmailOtp}    
                >
                    {!verifyEmailStatus ? 'VERIFY' : 'Resend'}
                </Button>}
                </Grid>
                {verifyEmailStatus && 
                <>
                <Grid item xs={9}>
                <Typography fontFamily="Brandon" mt={1} sx={{fontSize: "14px" ,color: '#fe7648'}} >We have sent a one time password to your email address. Please enter it below.
                  Remember to check the spam folder too!</Typography>
                <FormInput fullWidth variant="standard">
                    <CustomLabelInput shrink='true' htmlFor="otp">
                    One Time Password (OTP)
                    </CustomLabelInput>
                    <InputField
                    name="otp"
                    type="text"
                    id="otp"
                    size="small"
                    placeholder="Enter OTP"
                    onChange={handleEmailData}
                    value={emailVerification.otp}
                    sx={{border: '1px soliud red'}}
                    />
                </FormInput>
                </Grid>
                <Grid item xs={3} sx={{ textAlign: "center" }}>
                  <Button className="default-btn" variant="contained" onClick={handleVerifyEmailOtp}>
                    Verify
                  </Button>
                </Grid>
                </>
                }
          </Grid>

          <Grid container sx={{display:'flex', alignItems:'end' }}>
          <Grid item xs={9}>
                <FormInput fullWidth variant="standard">
                    <CustomLabelInput shrink='true' htmlFor="mobile">
                     Mobile <span style={{color: 'red'}}>*</span>
                    </CustomLabelInput>
                    <PhoneInput
                     country={"lu"}
                     id="mobile"
                     disabled={mobileVerification.disabled}
                    //  value={userForm.mobile}
                     onChange={(value, data) => {
                      setMobileVerification({...mobileVerification, mobile: value.slice(data.dialCode.length), countryCode: data.dialCode})
                     }}
                    />
                </FormInput>
                </Grid>
                <Grid item xs={3} sx={{ textAlign: "center" }}>
                {mobileVerification.status == 'verified' ? 
                <Typography className="verified-badge" component='h5'>Verified</Typography> :
                <Button className="default-btn" variant="contained" sx={{marginBottom:'2px'}}
                    onClick={handleCreateMobileOtp}>{!verifyMobileStatus ? 'VERIFY' : 'Resend'} </Button>
                }
                </Grid>
                {verifyMobileStatus && 
                <>
                <Grid item xs={9}>
                <FormInput fullWidth variant="standard">
                    <CustomLabelInput shrink='true' htmlFor="otp">
                    One Time Password (OTP)
                    </CustomLabelInput>
                    <InputField
                    name="otp"
                    type="text"
                    id="otp"
                    size="small"
                    placeholder="Enter OTP"
                    onChange={handleMobileData}
                    value={mobileVerification.otp}
                    sx={{border: '1px soliud red'}}
                    />
                </FormInput>
                </Grid>
                <Grid item xs={3} sx={{ textAlign: "center" }}>
                <Button className="default-btn" variant="contained" onClick={handleVerifyMobileOtp}>
                    Verify
                </Button>
                </Grid>
                </>
                }
          </Grid>
          <Grid item xs={12}>
                <FormInput fullWidth variant="standard">
                    <CustomLabelInput shrink='true' htmlFor="password">
                    Password <span style={{color: 'red'}}>*</span>
                    </CustomLabelInput>
                    <p style={{fontSize: '14px', fontFamily:'Brandon', color: '#fe7648', margin: 0}}>Password must contain: Minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character (can include #@$!%*?&)</p>
                    <InputField
                    name="password"
                    type={handlePasswords.passwordType}
                    id="password"
                    size="small"
                    placeholder="Enter Password"
                    onChange={(e) => {setUserForm({...userForm, password: e.target.value})}}
                    value={userForm.password}
                    sx={{position: 'relative'}}
                    />
                <Box sx={{position: 'absolute', bottom: '4px', right: '10px'}}>
                  {!handlePasswords.showPassword ? 
                  <VisibilityIcon sx={{color: '#fe7648', cursor: 'pointer'}} onClick={() => setHandlePasswords({...handlePasswords, showPassword: true, passwordType: 'text'})}/>
                   : <VisibilityOffIcon sx={{color: '#fe7648', cursor: 'pointer'}} onClick={() => setHandlePasswords({...handlePasswords, showPassword: false, passwordType: 'password'})}/>}
                </Box>
                </FormInput>
              </Grid>
              <Grid container sx={{display:'flex', alignItems:'end' }}>
              <Grid item xs={12}>
                <FormInput fullWidth variant="standard">
                    <CustomLabelInput shrink='true' htmlFor="cnf_password">
                    Confirm Password <span style={{color: 'red'}}>*</span>
                    </CustomLabelInput>
                    <InputField
                    name="cnf_password"
                    type={handlePasswords.cnfpasswordType}
                    id="cnf_password"
                    size="small"
                    placeholder="Enter Password"
                    onChange={(e) => {setUserForm({...userForm, cnfPassword: e.target.value})}}
                    value={userForm.cnfPassword}
                    sx={{position: 'relative'}}
                    />
                <Box sx={{position: 'absolute', bottom: '4px', right: '10px'}}>
                  {!handlePasswords.cnfPassword ? 
                  <VisibilityIcon sx={{color: '#fe7648', cursor: 'pointer'}} onClick={() => setHandlePasswords({...handlePasswords, cnfPassword: true, cnfpasswordType: 'text'})}/>
                   : <VisibilityOffIcon sx={{color: '#fe7648', cursor: 'pointer'}} onClick={() => setHandlePasswords({...handlePasswords, cnfPassword: false, cnfpasswordType: 'password'})}/>}
                </Box>
                </FormInput>
              </Grid>
              {/* <Grid item xs={3} sx={{ textAlign: "center" }}>
                <Typography component='h5' sx={{color: '#66bb6a', fontFamily:'Brandon', fontWeight: 'bold'}}>Verified</Typography>
              </Grid> */}
              </Grid>
              <Grid item xs={12} sx={{ marginTop: "25px", textAlign: 'center'}}>
                  <Button variant="contained"  className="default-btn" sx={{width: "35%"}} onClick={handleUpdateUserInfo}>
                    Continue
                  </Button>
                </Grid> 
        </div>
        </Grid>
      </Grid>
    </ThemeProvider>
    <DynamicInfoDialog 
      isOpen={passwordInfoDialog}
      handleCloseDialog={() => {setPasswordInfoDialog(false)}}
      title={"Password Must Contain:"}
      subtitle={"Minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character (can include #@$!%*?&)"}
    />
    <SnackBar
      open={snackbar.isOpen}
      handleClose={() => setSnackbar({...snackbar, isOpen: false})}
      duration={3000}
      msg={snackbar.message}
      type={snackbar.type}
    />
    </>
)
}

export default UserVerification