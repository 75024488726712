import React, { useEffect, useState } from "react"
import Masthead from "../../../../../Components/Masthead"
import { Avatar, Box, Button, Grid, Typography } from "@mui/material"
import { useNavigate } from "react-router-dom"
import { BackBtn } from "../../../../../Assets/assets"
import { getMeetingDetailsById, getMeetingList, updateMeetingStatusById } from "../../../../../Utils/Api"
import ConfirmationTextDialog from "../../../../../Components/Dialogs/confirmationTxt"
import Stack from '@mui/material/Stack';
import Pagination from '@mui/material/Pagination';


const MyMeeting = () => {
    const navigate = useNavigate()
    const [meetingList, setMeetingList] = useState([])
    const [changeStatusDialog, setChangeStatusDialog] = useState(false)
    const [changeStatusReason, setChangeStatusReason] = useState('')
    const [changeStatusType, setChangeStatusType] = useState('')
    const [bookingId, setBookingId] = useState('')
    const [page, setPage] = useState(1)
    const [count, setCount] = useState()

    useEffect(() => {
        getMeetingList(1).then((res) => {
            setMeetingList(res.data.data.results)
            setCount(res.data.data.count)
        })
    }, [])

const changeMeetingStatus = (status, bookingId) => {
    setChangeStatusType(status)
    setBookingId(bookingId)
    setChangeStatusDialog(true)
}

const handleUpdateStatus = () => {
    updateMeetingStatusById({
        "status": changeStatusType, 
        "notes": changeStatusReason
    }, bookingId).then(() => {
        setChangeStatusDialog(false)
        getMeetingList(page).then((res) => {
            setMeetingList(res.data.data.results)
        })
    })
}
const handleChangePage = (e, val) => {
    getMeetingList(val).then((res) => {
        setMeetingList(res.data.data.results)
    })
    setPage(val)
}
    return (
        <Box>
            <Masthead />
            <Box className='profile-container' sx={{ maxWidth: "85%", margin: "auto", mb: 5 }}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Box className="wrap-back-btn" onClick={() => { navigate('/dashboard') }}>{BackBtn()} Back</Box>
                    <Box className="wrap-back-btn" style={{ color: '#f48220', marginLeft: "5px" }} onClick={() => { navigate('/dashboard') }}> /Home</Box>
                    <Typography className="view-dog-heading" sx={{ mt: 5, mb: 5 }}>
                        My Meetings
                    </Typography>
                </Box>
                <Grid container xs={12}>
                {meetingList && meetingList.map((res    ) => {
                    return(
                        <Grid className="booking-req-container" spacing={2} item xs={12} md={4}>
                            <Grid className="meeting-req-wrapper" >
                            <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                                    <p style={{ margin: '0'}}><span style={{color: '#f48221'}}>ID:</span> {res.booking_id}</p>
                                    <Box sx={{border: '1px solid #017d01', color: '#017d01', padding:'0px 10px'}}>
                                        <p style={{margin: '10px 0px'}}>STATUS: <span style={{fontWeight: 'bold', textTransform: 'uppercase'}}>{res.status}</span></p>
                                    </Box>
                                </Box>
                                
                                <Box sx={{mt: 2}}>
                                    <p className="label-heading">Service Type</p>
                                    <p className="label-title">{res.service_type} { res.service_type == "Daycare" && (res.day_care_type == 'one_time' ? " - One Time" : " - Weekly")}</p>
                                </Box>
                                <Box sx={{mt: 2}}>
                                    <p className="label-heading">Meeting With</p>
                                    <p className="label-title">{res.sitter_name || '-'}</p>
                                </Box>
                                <Box sx={{mt:2}}>
                                    <p className="label-heading">Meeting Date & Time</p>
                                    <p className="label-title">{`${res.meeting_date} - ${res.meeting_time}`|| '-'}</p>
                                </Box>
                                {/* <Box sx={{mt:2}}>
                                    <p className="label-heading">Meeting Time</p>
                                    <p className="label-title">{res.meeting_time || '-'}</p>   
                                </Box> */}
                                <Box sx={{mt:2}}>
                                    <p className="label-heading">Meeting Location</p>
                                    <p className="label-title">{res.meeting_location_type || '-'}</p>
                                </Box>
                                {['open', 'Open'].includes(res.status) ? <Box className="action-btn" sx={{gap: "10px", mt:2}}>
                                    <Button onClick={() => changeMeetingStatus('cancelled', res.booking_id)}>Cancel Meeting</Button>
                                    <Button onClick={() => changeMeetingStatus('date_change_request', res.booking_id)}>Change Meeting</Button>
                                </Box> : <Box sx={{mt:2}}>
                                    <p className="label-heading">Status</p>
                                    <p className="label-title">{res.status || '-'}</p>
                                </Box>}
                            </Grid>
                        </Grid>
                    )
                })}
                </Grid>
                <Box className="pagination-data" sx={{display: 'flex', justifyContent: 'center'}}>
                    <Stack spacing={2}>
                        <Pagination count={Math.ceil(count/10)} rowsPerPage={10} color="primary" onChange={handleChangePage}/>
                    </Stack>    
                </Box>
            </Box>
            {changeStatusDialog && <ConfirmationTextDialog
             sumbitTxt={'Confirm'}
             cancelTxt={'Cancel'}
             placeholder={"Enter Reason"}
             isOpen={changeStatusDialog}
             title={`Want To ${changeStatusType == "cancelled" ? "Cancel" : "Change"} The Meeting?`}
             SubTitle={'Please tell us why so we can update the dog sitter too:'}
             reason={e => setChangeStatusReason(e)}
             handleCloseDialog={() => setChangeStatusDialog(false)}
             handleClick={handleUpdateStatus}
             />}
        </Box>
    )
}

export default MyMeeting